export const OPTIVAL_CONSTANTS = {
  SUPER_ADMIN: "Super Admin",
  SALES_TEAM: "Sales Team",
  CUSTOMER_SUCCESS: "Customer Success",
  ORGANIZATION_ADMIN: "Org Admin",
  OPERATIONS_ADMIN: "Operations Admin",
  CONTENT_ADMIN: "Content Admin",
  ADMIN: "Admin",
  CONTENT_EDITOR: "Content Editor",
  SUPERVISOR: "Supervisor",
  EMPLOYEE: "Employee",
  HOME_MENU: "Home",
  INSTRUKT_MENU: "Instrukt",
  SKILLZ_MENU: "Skillz",
  MANAGEMENT_MENU: "Management",
  ORGANIZATION_PROFILE_MENU: "Organization Profile",
  ONBOARD_ORGANIZATION_MENU: "Onboard Organisation",
  MANAGE_ORGANIZATION_MENU: "Manage Organisation",
  SIGNUP_REQUEST_MENU: "Signup Request",
  ERROR_MSG_UPLOAD_IMG: "Please Upload a Image (JPEG or PNG image)",
  API_URLS: {
    AUTH_LOGIN: "auth/login",
    AUTH_LOGIN_VERIFY_OTP: "auth/login/verifyotp",
    TEXT_TO_SPEECH: "texttospeech/synthesize",
    WORKFLOWS_LIST: "workflow/workflows",
    WORKFLOWS_LIST_BYSTATUS: "workflow/workflows/bystatus",
    WORKFLOW_ASSIGNED_GROUPS: "workflow/assignedgroups/workflowId",
    ASSIGN_WORKFLOW_TO_GROUPS:
      "workflow/assignWorkflow/organizationId/workflowId",
    REMOVE_ASSIGNED_WORKFLOW_GROUPS:
      "workflow/removeAssignedWorkflow/workflowId",
    SEARCH_GROUP_USERS: "group/autocomplete",
    SHARE_WORKFLOW: "share/workflow",
    VERSION_WORKFLOW: "workflow/workflowId/versionId",
    CLONE_WORKFLOW: "workflow/version/workflowId/versionId",
    CLONE_AS_NEW_WORKFLOW: "/workflow/clone",
    PUBLISH_WORKFLOW: "workflow/reviewversion/versionId",
    UNPUBLISH_WORKFLOW: "workflow/unpublish/workflowId/versionId",
    ENABLE_WORKFLOW: "workflow/enableWorkflow/workflowId",
    DISABLE_WORKFLOW: "workflow/disableWorkflow/workflowId",
    PROFILE_DATA: "userView/myProfile",
    GET_GROUP_DATA: "group",
    ORGANIZATION_DATA: "/organization/details",
    GET_LANGUAGE_LIST: "/language",
    CREATE_WORKFLOW_WITH_VERSION: "workflow/workflowwithversion",
    UPDATE_WORKFLOW: "/workflow/workflowId",
    DELETE_WORKFLOW: "/workflow/workflowId",
    DELETE_WORKFLOW_VERSION: "/workflow/workflowId/versionId",
    ARCHIVE_WORKFLOW_VERSION: "/workflow/archive/workflowId/versionId",
    DELETE_STEP: "/workflow",
    CREATE_WORKFLOW_STEP: "workflow/stepwithmediaitem",
    TAG: "/tag",
    UPDATE_TAG: "/tag/tagId",
    DELETE_TAG: "tag/tagId",
    DELETE_TAG_BATCH: "/tag/batch",
    ADD_BULK_TAGS: "/tag/addBulkTags/organizationId",
    ORGANIZATION_LANGUAGE: "organization/languages",
    UPDATE_STEP: "/workflow/updatestepwithmedia/workflowId/versionId/stepId",
    SUBMIT_FOR_REVIEW: "/workflow/submitforreview",
    REORDER_STEP: "/workflow/version/step",
    GET_GROUP_DATA_FOR_DASHBOARD: "/group/dashboard",
    GET_EDIT_WORKFLOW_DETAIL: "/workflow/versions",
    GET_NOTIFICATIONS: "/notification",
    MARK_AS_READ_NOTIFICATION: "/notification/markAsRead/notificationId",
    SIGN_UP_REQUEST: "/organization/signuprequest",
    COUNTRY_DATA: "https://countriesnow.space/api/v0.1/countries/capital",
    STATE_DATA: "https://countriesnow.space/api/v0.1/countries/states",
    ONBOARD_ORGANISATION: "/organization/onboard",
    UPDATE_ORGANISATION: "/organization/organizationId",
    GET_ORGANISATION_PLAN: "/organization/plans",
    GET_MANAGE_ORGANISATION: "/organization",
    GET_SIGNUP_REQUEST_DATA: "/organization/signuprequests",
    GET_ORGANIZATION_STATS_GROUPS: "organization/stats-and-latest-groups",
    GET_EMPLOYEES_BY_ORGANIZATION: "employee/organization/organizationId",
    GET_EMPLOYEES_BY_GROUP: "employee/groupId",
    GET_EMPLOYEES_NOT_GROUP: "employee/group/unassigned/groupId",
    ADD_GROUP_BY_ORGANIZATION: "group/organizationId",
    UPDATE_GROUP: "group/groupId",
    DELETE_GROUP: "group/groupId",
    DELETE_MULTIPLE_GROUP: "group/",
    GET_SKILLS: "/skill",
    GET_EDIT_SKILLS: "/skill/skillId",
    CREATE_SKILLS: "/skill",
    PREVIEW_TRANSLATE_SKILLS: "skill/preview-skill-translations",
    UPDATE_SKILLS: "/skill/skillId",
    DELETE_SKILLS: "/skill/skillId",
    ADD_BULK_SKILLS: "/skill/addBulkSkills",
    GET_USERS_NOT_ASSIGNED_FOR_SKILL: "/skill/unassigned/users/skillId",
    GET_USER_ASSIGNED_FOR_SKILL: "/skill/assigned/users/skillId",
    GET_UNMAPPED_SKILLS_WORKFLOW: "/skill/unmapped/workflows/skillId",
    GET_MAPPED_SKILLS_WORKFLOW: "/skill/mapped/workflows/skillId",
    ADD_UNASSIGNED_SKILLS_TO_EMPLOYEE: "/skill/add",
    REMOVE_SKILL_FROM_EMPLOYEE: "/skill/remove",
    MAP_SKILLS_TO_WORKFLOW: "/skill/map/workflow",
    UNMAP_SKILLS_FROM_WORKFLOWS: "/skill/un/map/workflow",
    GET_SKILLS_LEVELS: "/skill/levels",
    GET_SKILLS_MATRIX_USERS: "skillMatrix/users",
    GET_SKILL_MATRIX: "skillMatrix/skills",
    GET_SKILLS_OVERVIEW: "skillMatrix/overview",
    GET_SKILLS_TIME_SPENT: "skillMatrix/learning/time-spent",
    GET_SKILLS_DISTRIBUTION: "skillMatrix/distribution",
    GET_SKILLS_MONTHLY: "skillMatrix/monthly",
    GET_TOP_SKILLS: "skillMatrix/top",
    POST_TARGET_SKILL_LIST: "target/skill",
    SAVE_TARGET_SKILL: "target/skill/save",
    POST_TARGET_SKILL_BY_GROUP: "group/skill/target",
    SAVE_TARGET_SKILL_BY_GROUP: "group/skill/target/save",
    SEARCH_GROUP: "group/autocomplete/group",
    GET_SKILLS_WITH_GAP: "target/skills/most-gap",
    GET_SKILLS_TARGET_WITH_GAP: "groups/most-gap",
    SEARCH_SKILLS: "skill/autocomplete",
    CLEAR_FILTER_SKILLS_MATRIX: "filters/config/clear",
    GET_ROLES: "/employee/role",
    ADD_EMPLOYEE_USER: "/employee/organizationId",
    UPDATE_EMPLOYEE_USER: "/employee/organizationId/employeeId",
    GET_EMPLOYEES_BY_ORGANISATION: "/employee/organization/organizationId",
    ASSIGN_WORKFLOW_TO_EMPLOYEE:
      "employee/assignworkflowforbuild/employeeId/versionId",
    REMOVE_ASSIGN_WORKFLOW_FROM_EMPLOYEE:
      "employee/removeworkflowversionforbuild/employeeId/versionId",
    ADD_BULK_EMPLOYEES: "/employee/onboarding/organizationId",
    DELETE_EMPLOYEE_DATA: "/employee/organizationId/employeeId",
    DELETE_MULTIPLE_EMP_DATA: "/employee/",
    ASSIGN_BULK_SKILL_EMPLOYEES: "/employee/skill/bulk-assignment/skillId",
    UPLOAD_PROFILE_PICTURE: "/employee/profilepicture",
    ORGANIZATION_LANGUAGE_DISABLE: "organization/languages-include-disabled",
    UPDATE_ORGANISATION_DETAILS: "/organization/update/organizationId",
    GET_DASHBOARD_OVERVIEW: "/skillMatrix/dashboard-overview",
    GET_TOTAL_USER_LINECHART: "/employee/monthly",
    GET_TOTAL_WORKFLOW_LINECHART: "/workflow/line-chart",
    GET_TOTAL_SKILL_LINE_CHART: "/skillMatrix/line-chart",
    GET_WORKFLOW_BY_SKILLS: "/skillMatrix/dashboard/workflows-by-skills",
    GET_WORKFLOW_CREATION_MONTHLY: "/workflow/creation/monthly",
    GET_USERS_BY_GROUP: "/group/users-by-group",
    GET_SKILLS_WITH_COUNT: "/skillMatrix/top/skills-with-counts"
  },
  DEFAULT_LANGUAGE_CODE: "en",
  LANGUAGE_CODE_SESSION_KEY: "language_code",
  RESEND_OTP_COUNT: 6,
  RESEND_OTP_COUNT_COOKIE_EXPIRE_TIME: 10 * 60 * 1000, //milliseconds
  RESEND_OTP_REACHED_MSG:
    "Maximum OTP generate limit has been reached. Try again 10 minutes later.",
  EMAIL_OTP_SUCCESS_MSG: "Email OTP generated successfully.",
  OTP_VERFIY_LOGIN_SUCCESS_MSG: "OTP verified and login successfully.",
  OTP_VERFIY_LOGIN_ERROR_MSG: "OTP verification failed.",
  ERROR_CODE_MSG: {
    WORKFLOW_ALREADY_ASSIGNED_TO_GROUP:
      "Workflow already assigned to group(s).",
  },
  USER_SESSION_TIMEOUT: "Your session has been expired. Please login again.",
  SERVICE_NOT_FOUND: "Service not found.",
  SERVICE_UNAVAILABLE: "Service temporarily unavailable.",
  BAD_REQUEST: "BAD REQUEST",
  LICENSE_EXPIRED: "License has expired. Please contact sales@opva.ai"
};
