import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { TableHeadCell } from "../../../shared/models/TableModels";
import { useAPI } from "../../../shared/services/api/API";
import {
  WorkflowConstants,
  WorkflowTableHeaderCells,
} from "../models/Workflow";

export const useWorkflowUtils = () => {
  const { checkRole } = useAPI();

  const getWorkflowTableHeaderCells = (
    tabValue: WorkflowConstants
  ): TableHeadCell[] => {
    const tableHeaderCells: TableHeadCell[] = [
      WorkflowTableHeaderCells.WORKFLOW,
      WorkflowTableHeaderCells.DESCRIPTION,
    ];
    if (tabValue === WorkflowConstants.PUBLISHED) {
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.SUPERVISOR,
          OPTIVAL_CONSTANTS.CONTENT_EDITOR
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.VERSIONS,
        );
      }
      if (checkRole([OPTIVAL_CONSTANTS.SUPERVISOR])) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.GROUPS_ASSIGNED,
        );
      }
      if (
        checkRole([
          OPTIVAL_CONSTANTS.SUPERVISOR,
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.ASSIGN,
        );
      } 
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.SUPERVISOR,
          OPTIVAL_CONSTANTS.CONTENT_EDITOR
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.SHARE,
        );
      }
      if (checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,])) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.COPY_CREATE,
          WorkflowTableHeaderCells.TURN_ON_OFF,
          WorkflowTableHeaderCells.UNPUBLISH
        );
      }
    } else if (tabValue === WorkflowConstants.IN_REVIEW) {
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.CREATED_DATE,
          WorkflowTableHeaderCells.UPDATED_DATE
        );
      }
    } else if (tabValue === WorkflowConstants.WORK_IN_PROGRESS) {
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.CONTENT_EDITOR,
          OPTIVAL_CONSTANTS.SUPERVISOR
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.CREATED_DATE,
          WorkflowTableHeaderCells.UPDATED_DATE,
          WorkflowTableHeaderCells.STATUS
        );
      }
    } else if (tabValue === WorkflowConstants.DRAFT) {
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.CONTENT_EDITOR
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.CREATED_DATE,
          WorkflowTableHeaderCells.UPDATED_DATE
        );
      }
    } else if (tabValue === WorkflowConstants.ARCHIVED) {
      if (
        checkRole([
          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
          OPTIVAL_CONSTANTS.CONTENT_EDITOR
        ])
      ) {
        tableHeaderCells.push(
          WorkflowTableHeaderCells.CREATED_DATE,
          WorkflowTableHeaderCells.UPDATED_DATE
        );
      }
    }
    return tableHeaderCells;
  };
  return { getWorkflowTableHeaderCells };
};
