import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const TranslateSkillsDialog = (props: any) => {
  const {
    openDialog,
    isEdit,
    handleCreateSkills,
    handleCloseDialog,
    languageList,
    translations,
    handleInputChange,
  } = props;
  const { t } = useTranslation();
  const filteredLanguages = languageList?.filter(
    (lang: any) => translations?.translatedNames.hasOwnProperty(lang?.language_name) || translations?.translatedDescriptions.hasOwnProperty(lang?.language_name)
  );

  return (
    <Dialog maxWidth={"lg"} open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>
        {isEdit ? t("opva.reviewAndUpdate") : t("opva.reviewAndCreate")}
      </DialogTitle>
      <DialogContent>
        <table>
          <thead>
            <tr>
              <th className="px-4 py-2"></th>
              <th className="px-4 py-2">{t("opva.name")}</th>
              <th className="px-4 py-2">{t("opva.description")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredLanguages?.map((lang: any) => {
              const name = translations?.translatedNames[lang?.language_name];
              const description = translations?.translatedDescriptions[lang?.language_name];
              return (
                <tr key={lang?.id} className="hover:bg-gray-100">
                  <td className="fw-bold px-4 py-2">{lang?.display_name}</td>
                  <td className="px-3 py-2">
                    <TextField
                      variant="outlined"
                      style={{ width: "320px" }}
                      value={name || ""}
                      onChange={(e) => {
                        handleInputChange(
                          lang?.language_name,
                          "translatedNames",
                          e.target.value
                        );
                      }}
                      disabled={!isEdit}
                    />
                  </td>
                  <td className="px-3 pt-3">
                    <TextField
                      variant="outlined"
                      style={{ width: "320px" }}
                      value={description || ""}
                      onChange={(e) => {
                        handleInputChange(
                          lang?.language_name,
                          "translatedDescriptions",
                          e.target.value
                        );
                      }}
                      multiline
                      rows={2}
                      disabled={!isEdit}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleCloseDialog}>
          {t("opva.cancel")}
        </Button>
        <Button
          onClick={(e) => {
            handleCreateSkills(e);
          }}
          color="primary"
          variant="contained"
        >
          {isEdit ? t("opva.updateSkill") : t("opva.createSkill")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
