import {
  InputAdornment,
  FormControl,
  Typography,
  Button,
  Link,
  Input,
  Box,
  Tabs,
  Tab,
  Switch,
  Grid,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import "./Skills.scss";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { useEffect, useState, KeyboardEvent, useMemo, useContext } from "react";
import { SkillsData } from "../../models/SkillsData";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { tableSorting } from "../../../../shared/utils/Utils";
import { useNavigate } from "react-router-dom";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { SkillsConstants } from "../../models/SkillsConstants";
import { useSkillsUtils } from "./SkillsUtil";
import { useTranslation } from "react-i18next";
import { SkillsManagement } from "../skills-management/SkillsManagement";
import { SkillOverview } from "../skills-overview/SkillOverview";
import { SkillsContext } from "../../contexts/SkillsContext";
import { TopSkillsTable } from "../skills-overview/top-skills-table/TopSkillsTable";
import { TimeSpentLeaningSkills } from "../skills-overview/time-spent-learning-skills/TimeSpentLeaningSkills";
import { SkillsGainedMonth } from "../skills-overview/skills-gained-month/SkillsGainedMonth";
import { SkillsMatrix } from "../skills-matrix/SkillsMatrix";
import { GapAnalysis } from "../gap-analysis/GapAnalysis";

export const Skills = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(t("opva.overview"));
  const navigate = useNavigate();
  const [skillData, setSkillsData] = useState<SkillsData[]>([]);
  const [displaySkillsData, setDisplaySkillsData] = useState<SkillsData[]>([]);
  const [editSkillData, setEditSkillData] = useState<SkillsData>();
  const { httpGet, handleAlertBar, checkRole } =
    useAPI();
  const [openDialog, setOpenDialog] = useState(false);
  const [skillObj, setSkillObj] = useState({
    skillName: "",
    skillDescription: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [mode, setMode] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [skillDataVisible, setskillDataVisible] = useState<SkillsData[]>([]);
  const [skillId, setSkillId] = useState<number>(0);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const { getSkillzHeaderCell } = useSkillsUtils();
  const [skillzHeaderCell, setSkillzHeaderCell] = useState<TableHeadCell[]>([]);
  const [matrixListOption, setMatrixListOption] = useState({
    byUser: true
  });
  const [gapAnalysisListOption, setGapAnalysisListOption] = useState({
    byGroup: true
  });

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: '#1890ff',
            // opacity: 0.5,
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: '#1890ff',
            }
          },
          track: {
            opacity: 0.5,
            backgroundColor: '#1890ff',
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.5,
              backgroundColor: '#1890ff',
            }
          }
        }
      }
    }
  })

  const handleChange =  (event: any) => {
    setMatrixListOption({ ...matrixListOption, [event.target.value]: event.target.checked });
  };

  const handleGapAnaysisToggleChange =  (event: any) => {
    setGapAnalysisListOption({ ...gapAnalysisListOption, [event.target.value]: event.target.checked });
  };

  const {
    topViewMore,
    timeSpentViewMore,
    skillsGainViewMore,
    validatedViewMore,
    targetGapViewMore,
    skillsBarChartViewMore,
    skillsDistributionPieChartViewMore,
    updateTargetGapLessMore,
    updateSkillsDistributionPieChartLessMore,
    updateSkillsBarChartLessMore,
    updateValidateLessMore } =
    useContext(SkillsContext);

  const handleTabChange = (_event: any, newValue: any) => {
    setTabValue(newValue);
    setOpenDialog(false);
    updateValidateLessMore(false);
    updateTargetGapLessMore(false);
    updateSkillsDistributionPieChartLessMore(false);
    updateSkillsBarChartLessMore(false);
  };

  const handleAlertDialogOpen = (groupId: number) => {
    setSkillId(groupId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setSkillId(0);
    setIsAlertDialog(false);
  };

  const handleAddSkills = async () => {
    setSkillObj({ skillName: "", skillDescription: "" });
    navigate("/add-skill")
    // setOpenDialog(true);
    // setMode("add");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditSkillsClick = (data: SkillsData) => {
    setOpenDialog(true);
    setSkillObj({ skillName: data.name, skillDescription: data.description });
    setMode("edit");
    setEditSkillData(data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const startIndex = newPage * rowsPerPage;
    setDisplaySkillsData([
      ...skillData.slice(startIndex, startIndex + rowsPerPage),
    ]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setDisplaySkillsData(skillData.slice(0, parseInt(event.target.value, 10)));
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      fetchSkillsData();
    }
  };
  const handleSorting = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEmptyPage = (data: any) => {
    const sliceArr = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    if (!sliceArr.length) {
      setPage(page === 0 ? 0 : page - 1);
    }
  };

  let queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
    languageId: 1
  };

  const fetchSkillsData = async () => {
    setIsLoading(true);
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS, queryParams).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoading(false);
          } else {
            setSkillsData(response.data);
            setDisplaySkillsData(response.data.slice(0, rowsPerPage));
            setIsLoading(false);
            handleEmptyPage(response.data);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const extractDate = (dateString: string) => {
    const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };

  const toggleDescriptionText = (skillData: SkillsData) => {
    skillData.isDescriptionViewMore = !skillData.isDescriptionViewMore;
    setskillDataVisible([...skillDataVisible]);
  };

  const renderTruncateDescription = (skillData: SkillsData) => {
    return !skillData.isDescriptionViewMore ? (
      <>
        {skillData.description.substring(
          0,
          SkillsConstants.SKILL_DESCRIPTION_LENGTH
        )}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(skillData)}
        >
          {t("opva.viewMore")}
        </Link>
      </>
    ) : (
      <>
        {skillData.description}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(skillData)}
        >
          {t("opva.viewLess")}
        </Link>
      </>
    );
  };

  useMemo(() => {
    const propertyType = orderBy === "usersAssigned" ? "number" : "string";
    const sortedList = tableSorting(skillData, order, orderBy, propertyType);
    setSkillsData(sortedList);
    setDisplaySkillsData(
      sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [order, orderBy, page, skillData]);

  const getOrganizationSupportLanguage = async () => {
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_LANGUAGE,
      {},
      false
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          if(response.data?.length > 0) {
            const languageCode = sessionStorage.getItem("language_code");
            const selectedLang = response.data?.filter((lang: any) => lang.aws_language_code === languageCode);
            queryParams['languageId'] = selectedLang[0]?.id;
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getOrganizationSupportLanguage();
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        fetchSkillsData();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        fetchSkillsData();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  useEffect(() => {
    if(validatedViewMore) {
      setTabValue(t("opva.skillsManagement"));
    }
  }, [validatedViewMore]);

  useEffect(() => {
    if(targetGapViewMore) {
      setTabValue(t("opva.gapAnalysis"));
      handleGapAnaysisToggleChange({target: {value: 'byGroup', checked: true}})
    }
  }, [targetGapViewMore]);

  useEffect(() => {
    if(skillsBarChartViewMore) {
      setTabValue(t("opva.gapAnalysis"));
      handleGapAnaysisToggleChange({target: {value: 'byGroup', checked: false}})
    }
  }, [skillsBarChartViewMore]);

  useEffect(() => {
    if(skillsDistributionPieChartViewMore) {
      setTabValue(t("opva.skillMatrix"));
    }
  }, [skillsDistributionPieChartViewMore]);

  return (
    <div className="skillz-container">
      <div className="bg-light-blue px-4 pt-2 mb-2">
        <Breadcrumb isFromOPVA={true} pathList={[{ name: t("opva.skillz"), path: "skillz" }]} />
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fw-bold mb-3">{t("opva.skill")}</h3>
          {tabValue === t("opva.skillsManagement") && (
            <div>
              <FormControl sx={{ marginRight: 2 }} variant="outlined">
                <Input
                  id="search"
                  type="text"
                  placeholder={t("opva.searchSkill")}
                  value={searchText}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchText && (
                      <InputAdornment position="end">
                        <Link component="button" title={t("opva.clear")}>
                          <CloseIcon
                            fontSize="small"
                            onClick={() => setSearchText("")}
                          />
                        </Link>
                      </InputAdornment>
                    )
                  }
                  size="small"
                  sx={{ paddingLeft: "10px" }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={handleSearchKeyEnter}
                />
              </FormControl>
              {/* <TuneIcon color="primary" style={{ marginRight: "10px" }} /> */}
              {checkRole([
                OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                OPTIVAL_CONSTANTS.SUPER_ADMIN,
                OPTIVAL_CONSTANTS.SUPERVISOR,
                OPTIVAL_CONSTANTS.CONTENT_ADMIN,
              ]) && (
                <Button
                  variant="contained"
                  onClick={handleAddSkills}
                  className="mb-2"
                >
                  {t("opva.addSkill")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }} className="px-4">
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className="tabs-container"
          >
            <Tab
              value={t("opva.overview")}
              label={t("opva.overview")}
              sx={{ textTransform: "none" }}
            />
            <Tab
              value={t("opva.skillsManagement")}
              label={t("opva.skillsManagement")}
              sx={{ textTransform: "none" }}
            />
            <Tab
              value={t("opva.skillMatrix")}
              label={t("opva.skillMatrix")}
              sx={{ textTransform: "none" }}
            />
            <Tab
              value={t("opva.gapAnalysis")}
              label={t("opva.gapAnalysis")}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
          {tabValue === t("opva.skillMatrix")  && (
            <div>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography
                    variant={!matrixListOption.byUser ? "h6" : "body2"}
                    color={!matrixListOption.byUser ? "primary" : "grey"}
                  >
                    {t("opva.bySkill")}
                  </Typography>
                </Grid>
                <Grid item>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={matrixListOption.byUser}
                      onChange={(e) => handleChange(e)}
                      value="byUser"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item>
                  <Typography
                    variant={matrixListOption.byUser ? "h6" : "body2"}
                    color={matrixListOption.byUser ? "primary" : "grey"}
                  >
                    {t("opva.byUser")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
          {tabValue === t("opva.gapAnalysis")  && (
            <div>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography
                    variant={!gapAnalysisListOption.byGroup ? "h6" : "body2"}
                    color={!gapAnalysisListOption.byGroup ? "primary" : "grey"}
                  >
                    {t("opva.bySkill")}
                  </Typography>
                </Grid>
                <Grid item>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={gapAnalysisListOption.byGroup}
                      onChange={(e) => handleGapAnaysisToggleChange(e)}
                      value="byGroup"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid item>
                  <Typography
                    variant={gapAnalysisListOption.byGroup ? "h6" : "body2"}
                    color={gapAnalysisListOption.byGroup ? "primary" : "grey"}
                  >
                    {t("opva.byGroup")}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        {tabValue === t("opva.skillsManagement") && (
          <SkillsManagement
            searchText={searchText}
            openDialog={openDialog}
            skillObj={skillObj}
            mode={mode}
          />
        )}
        {tabValue === t("opva.overview") &&
          (topViewMore ? (
            <TopSkillsTable />
          ) : timeSpentViewMore ? (
            <TimeSpentLeaningSkills />
          ) : skillsGainViewMore ? (
            <SkillsGainedMonth />
          ) : (
            <SkillOverview />
          ))}
        {tabValue === t("opva.skillMatrix") && (
          <SkillsMatrix byUser={matrixListOption.byUser} />
        )}
        {tabValue === t("opva.gapAnalysis") && <GapAnalysis byGroup={gapAnalysisListOption.byGroup}/>}
      </Box>
    </div>
  );
};
